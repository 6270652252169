import * as React from 'react';
import { random } from 'lodash';

import Config from '../Config.json';
import Axios from 'axios';

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class ConfigService {
    public static configName = 'Config' + random(99999, false).toString();
    static get globalConfig(): typeof Config {
        return ((globalThis as any) ?? window)[ConfigService.configName];
    }
    static set globalConfig(value: typeof Config) {
        ((globalThis as any) ?? window)[ConfigService.configName] = value;
    }

    public static get(): PromiseLike<typeof Config> {
        const a = Config.Security; // have to use Config to have it imported
        if (ConfigService.globalConfig) {
            return Promise.resolve(ConfigService.globalConfig);
        } else {
            // the url is agumented to prevent caching of the request
            return Axios.get(`/Config.json?${this.configName}`, {
                method: 'POST',
                headers: { 'Cache-Control': 'max-age=2' },
            }).then((x) => {
                ConfigService.globalConfig = x.data;
                return ConfigService.globalConfig;
            });
        }
    }

    public static getSync(): typeof Config {
        return ConfigService.globalConfig;
    }

  
}
