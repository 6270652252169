// AppRoutes.ts
import React from 'react';
import { AppRoute } from './Models/AppRoute';
import EmptyLayout from '../Components/Layout/EmptyLayout';
import LandingLayout from '../Components/Layout/LandingLayout';
import Landing from '../Pages/Landing';
import RequestAccountDeletion from '../Pages/RequestAccountDeletetion';

const AppRoutes: AppRoute[] = [
    {
        path: '/',
        element: <Landing />,
        layout: LandingLayout,
    },
    {
        path: '/RequestAccountDeletion',
        element: <RequestAccountDeletion />,
        layout: LandingLayout,
    },
];

export { AppRoutes };
