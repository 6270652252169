import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './Components/App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ConfigService from '../src/Core/ConfigService';

ConfigService.get().then(() => {
    const container = document.getElementById('root');
    if (container) {
        const root = createRoot(container);  // Create a root with the container element
        root.render(<App />);
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
