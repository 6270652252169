/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Client } from './Api';
import getSecuredAxiosInstance, { ExtendedAxios } from './SecuredAxios';
import Config from '../../Core/Config.json';

export class SecuredClient extends Client {
    public axiosInstance: ExtendedAxios;

    public get handleSecurityError(): boolean | undefined {
        return this.axiosInstance.handleSecurityError;
    }

    public set handleSecurityError(newValue: boolean | undefined) {
        this.axiosInstance.handleSecurityError = newValue;
    }

    public get handleGeneralError(): boolean | undefined {
        return this.axiosInstance.handleGeneralError;
    }

    public set handleGeneralError(newValue: boolean | undefined) {
        this.axiosInstance.handleGeneralError = newValue;
    }

    public get showLoading(): boolean | undefined {
        return this.axiosInstance.showLoading;
    }

    public set showLoading(newValue: boolean | undefined) {
        this.axiosInstance.showLoading = newValue;
    }

    constructor() {
        const axiosInstance = getSecuredAxiosInstance();
        super(Config.apiBaseUrl, axiosInstance);
        this.axiosInstance = axiosInstance;
    }
}
