import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import EmptyLayout from '../Layout/EmptyLayout';
import { RoutesRenderer } from '../Layout/MainContent';
import './App.css';
import '../../Assests/styles/react-transitions.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <div className="transition-container">
                    <RoutesRenderer defaultLayout={EmptyLayout} />
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
