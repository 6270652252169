import * as React from 'react';
import { Button, Col } from 'antd';
import { AppstoreAddOutlined, GoogleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export interface Props {
    children?: React.ReactNode;
}

const Landing: React.FC<Props> = () => {
    const navigate = useNavigate();

    const handleAppStoreClick = () => {
        window.open('https://www.apple.com/app-store/', '_blank'); // Open App Store link
    };

    const handlePlayStoreClick = () => {
        window.open('https://play.google.com/store', '_blank'); // Open Play Store link
    };

    const handleAccountDeletion = () => {
        navigate('/RequestAccountDeletion');
    };

    return (
        <>
            <Col className="sudoku-Nav-button-container">
                <Button className="sudoku-Nav-button" type="primary" onClick={handleAppStoreClick}>
                    <div className="button-content">
                        <AppstoreAddOutlined className="button-icon" />
                        <span className="button-text">View in App Store</span>
                    </div>
                </Button>
            </Col>
            <Col className="sudoku-Nav-button-container">
                <Button className="sudoku-Nav-button" type="primary" onClick={handlePlayStoreClick}>
                    <div className="button-content">
                        <GoogleOutlined className="button-icon" />
                        <span className="button-text">View in Play Store</span>
                    </div>
                </Button>
            </Col>
            <Col className="sudoku-Nav-button-container">
                <Button className="sudoku-Nav-button" danger onClick={handleAccountDeletion}>
                    <div className="button-content">
                        <DeleteOutlined className="button-icon" />
                        <span className="button-text">Request Account Deletion</span>
                    </div>
                </Button>
            </Col>
        </>
    );
};

export default Landing;
