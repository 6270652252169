import * as React from "react";
import { Outlet } from "react-router-dom";

export interface Props {
    children?: React.ReactNode;
}

const LandingLayout: React.FC<Props> = ({ children }) => {
    return (
        <>
            {children}
            {/*<Outlet />*/}
        </>
    );
};

export default LandingLayout;
