import { IRequestDeletePlayerDataCommandRequest, RequestDeletePlayerDataCommandRequest } from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import Utility from '../../../Core/Utility/Utility';

export default class AccountDeletionHandler {
    RequestAccountDeletion(model: IRequestDeletePlayerDataCommandRequest) {
        let client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        client.handleSecurityError = true;

        let request = new RequestDeletePlayerDataCommandRequest(model);
        return client.requestDeletePlayerData(request);
    }

}
