import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const SLanding = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align elements to the top */
  min-height: 100vh;
  height: 100%;
  text-align: -webkit-center;
  background: linear-gradient(135deg, #1E4472, #0d2e4c);

  .sudoku-land-container {
      display: flex; 
      align-self: center;
      margin-top: -3rem;
  }

  .sudoku-land-buttons-container {
      padding-top: 5rem;
      display: flex; 
      flex-direction: column;
      row-gap: 1.5rem;
  }

  .sudoku-Nav-button-container {
      /* Additional styles if needed */
  }

  .sudoku-Nav-button {
      height: 5rem; 
      font-size: 1rem; 
      width: 16rem; 
      display: flex; /* Flex to allow alignment adjustments */
      align-items: center; /* Align items vertically */
      justify-content: flex-start; /* Align items to the left */

          .button-content {
              display: flex; /* Use flexbox for the icon and text */
              align-items: center; /* Center items vertically */
              width: 100%; /* Full width for centering text */
              justify-content: center; /* Center the text */
      }
          .button-icon {
              font-size: 2rem; /* Size of the icons */
              margin-right: 1rem; /* Space between icon and text */
      }
  }

  

  

  .button-text {
      flex-grow: 1; /* Take up remaining space to center the text */
      text-align: center; /* Center the text */
  }
`;

export const MainLogo = styled.img`
  max-height: 50vh;
  width: auto;
  display: block;
  margin-right: 2em; /* Space between the logo and the buttons */
  object-fit: contain; /* Ensures the image scales correctly */
`;
