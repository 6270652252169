import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BackwardOutlined, SendOutlined } from '@ant-design/icons';
import AccountDeletionHandler from './AccountDeletionHandler'; 

const Container = styled.div`
    max-width: 70%;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .full-width {
        width: 100%;
    }

    .delete-form-input {
        max-width: 100%;
        width: 22rem;
    }

    .delete-form-button-container {
        padding-top: 2rem;
    }

    .delete-form-button {
        height: 2.5rem;
        font-size: 1rem;
        width: 22rem;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .button-content {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
        }
    }
`;

const Title = styled.h2`
    text-align: center;
    color: #333;
`;

const Label = styled.label`
    font-weight: bold;
    display: block;
    margin-top: 10px;
    color: #555;
`;

const Message = styled.div`
    margin-top: 20px;
    text-align: center;
    color: green;
`;

const ErrorMessage = styled(Message)`
    color: red;
`;

const RequestAccountDeletionForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const handler = new AccountDeletionHandler(); 

    const handleBack = () => {
        navigate('/');
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setResponseMessage(''); // Clear previous messages
        setIsError(false);

        const model = {
            email,
        };

        try {
            const response = await handler.RequestAccountDeletion(model);

            if (response) {
                setResponseMessage(`${response.message}`);
                // You can show additional success info if needed here
            } else {
                setIsError(true);
                setResponseMessage("An error occurred. Please try again.");
            }
        } catch (error) {
            setIsError(true);
            setResponseMessage("An error occurred. Please try again.");
        }
    };

    return (
        <Container>
            <Title>Request Player Data Deletion</Title>
            <form onSubmit={handleSubmit}>
                <Row className="full-width">
                    <Col className="full-width delete-form-input-container">
                        <Label htmlFor="email">Player Email:</Label>
                        <Input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter player email"
                            className="delete-form-input"
                            required
                        />
                    </Col>
                </Row>

                <Row className="full-width">
                    <Col className="full-width delete-form-button-container">
                        <Button className="delete-form-button" type="primary" htmlType="submit">
                            <div className="button-content">
                                <SendOutlined className="button-icon" />
                                <span className="button-text">Request Account Deletion</span>
                            </div>
                        </Button>
                    </Col>

                    <Col className="full-width delete-form-button-container">
                        <Button className="delete-form-button" type="default" onClick={handleBack}>
                            <div className="button-content">
                                <BackwardOutlined className="button-icon" />
                                <span className="button-text">Back</span>
                            </div>
                        </Button>
                    </Col>
                </Row>
            </form>
            {isError ? (
                <ErrorMessage>{responseMessage}</ErrorMessage>
            ) : (
                <Message>{responseMessage}</Message>
            )}
        </Container>
    );
};

export default RequestAccountDeletionForm;
