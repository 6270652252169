import * as React from "react";
import { Outlet } from "react-router-dom";

export interface Props {
    children?: React.ReactNode;
}

const EmptyLayout: React.FC<Props> = ({ children }) => {
    return (
        <div>
            <header>Header</header>
            <main>
                <div>Hello</div>
                {children}
                {/*<Outlet />*/}
            </main>
            <footer>Footer</footer>
        </div>
    );
};

export default EmptyLayout;
