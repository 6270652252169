import * as React from 'react';
import { notification, message } from 'antd';
import hash from 'object-hash';
import { ReactNode } from 'react';
import { Notice } from '../Models/Notice';

// Define the allowed notification types
export type NotificationType = 'success' | 'info' | 'warning' | 'error';
const MAX_NOTICE_TYPE_COUNT = 2;

export default class NotificationService {
    private static notices: Notice[] = [];
    private static loadingCount = 0;
    private static hider: any;

    public static info(
        header: ReactNode,
        body?: ReactNode,
        duration?: number,
        shouldPersist?: boolean
    ) {
        NotificationService.Show('info', header, body, duration, shouldPersist);
    }

    public static success(
        header: ReactNode,
        body?: ReactNode,
        duration?: number,
        shouldPersist?: boolean
    ) {
        NotificationService.Show('success', header, body, duration, shouldPersist);
    }

    public static error(
        header: ReactNode,
        body?: ReactNode,
        duration?: number,
        shouldPersist?: boolean
    ) {
        NotificationService.Show('error', header, body, duration, shouldPersist);
    }

    public static warn(
        header: ReactNode,
        body?: ReactNode,
        duration?: number,
        shouldPersist?: boolean
    ) {
        NotificationService.Show('warning', header, body, duration, shouldPersist);
    }


    /**
     * Show notification method.
     * @param type Notification type (success, info, warning, error).
     * @param header Header for the notification.
     * @param body Body content of the notification.
     * @param duration Duration of the notification display.
     * @param shouldPersist Whether the notification should persist.
     */
    static Show(
        type: NotificationType, // Change to NotificationType
        header: ReactNode,
        body: ReactNode,
        duration: number | undefined,
        shouldPersist: boolean | undefined
    ) {
        const key = this.createMessageKey(type, header, body);
        const notices = this.notices;

        const sameType = notices.filter((x) => x.type === type);

        if (sameType.length > MAX_NOTICE_TYPE_COUNT - 1) {
            const similarNotice = sameType.filter((x) => x.key === key);

            if (similarNotice.length) {
                NotificationService.removeNotice(similarNotice[0], notices);
            } else {
                NotificationService.removeOlderNotice(sameType, notices);
            }
        }

        notices.push({ type, key, header, body, shouldPersist });
        notification[type]({
            key,
            message: header,
            description: body,
            duration: shouldPersist ? 0 : duration,
        });
    }

    public static CloseNonPersistentNotices() {
        const notices = this.notices;
        notices.filter((x) => !x.shouldPersist).forEach((x) => this.removeNotice(x, notices));
    }
    public static showLoader() {
        this.loadingCount++;

        if (this.loadingCount && !this.hider) {
            this.hider = message.loading('', 0);
        }
    }

    public static hideLoader() {
        this.loadingCount--;

        if (this.loadingCount <= 0 && this.hider) {
            this.hider();
            this.hider = undefined;
        }
    }

    public static resetHider() {
        this.loadingCount = 0;
        if (this.hider) {
            this.hider();
        }
    }
    private static createMessageKey = (type: NotificationType, header: ReactNode, body: ReactNode) =>
        hash((type as string) + header + body);

    private static removeOlderNotice(sameType: Notice[], notices: Notice[]) {
        let oldestOrNonPersistentNotice: Notice | undefined = sameType[0];
        if (sameType[0].shouldPersist) {
            oldestOrNonPersistentNotice = sameType[1].shouldPersist ? sameType[0] : sameType[1];
        }
        NotificationService.removeNotice(oldestOrNonPersistentNotice, notices);
    }

    private static removeNotice(notice: Notice, notices: Notice[]) {
        notification.destroy(notice.key);
        notices.splice(notices.indexOf(notice), 1);
    }

    
}
