import * as React from 'react';
import { Row, Col } from 'antd';
import { MainLogo, SLanding } from '../../Components/Layout/LandingLayout/LandingLayout.Styled';
import Landing from '../../Components/Content/Landing/Landing';
import mainLogo from '../../Assests/images/sasudokuvs.png';

export interface Props {
    children?: React.ReactNode;
}

const LandingPage: React.FC<Props> = ({ children }) => {
    return (
        <SLanding className="react-transition fade-in">
            <Row className="sudoku-land-container" justify="center" align="middle">
                <Col className= "sudoku-land-logo-container" xs={{ span: 24 }} md={{ span: 12 }}>
                    <MainLogo src={mainLogo} alt="Sudoku Logo" />
                </Col>
                <Col className = "sudoku-land-buttons-container" xs={{ span: 24, offset: 0 }} md={{ span: 10, offset : 2 }}>
                    <Landing />
                </Col>
            </Row>
        </SLanding>
    );
};

export default LandingPage;
